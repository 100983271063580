/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (charge types)
 * API for managing Charge Types within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const NetEffectModel = {
    Debit: 'Debit',
    Credit: 'Credit'
} as const;
export type NetEffectModel = typeof NetEffectModel[keyof typeof NetEffectModel];


export function NetEffectModelFromJSON(json: any): NetEffectModel {
    return NetEffectModelFromJSONTyped(json, false);
}

export function NetEffectModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetEffectModel {
    return json as NetEffectModel;
}

export function NetEffectModelToJSON(value?: NetEffectModel | null): any {
    return value as any;
}

