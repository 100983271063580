/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The type of direct debit
 * @export
 */
export const DirectDebitTypeModel = {
    AmountDue: 'Amount Due',
    AdHocAmount: 'Ad-hoc Amount'
} as const;
export type DirectDebitTypeModel = typeof DirectDebitTypeModel[keyof typeof DirectDebitTypeModel];


export function DirectDebitTypeModelFromJSON(json: any): DirectDebitTypeModel {
    return DirectDebitTypeModelFromJSONTyped(json, false);
}

export function DirectDebitTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectDebitTypeModel {
    return json as DirectDebitTypeModel;
}

export function DirectDebitTypeModelToJSON(value?: DirectDebitTypeModel | null): any {
    return value as any;
}

