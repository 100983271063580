import { useQuery } from "@ldms/mui-sdk/cqrs";
import * as agreementKeys from "api/agreements/payment-instructions/keys";
import { useApi } from "common/providers/ApiProvider";
import { AgreementQueryApi } from "generated/core/apis/AgreementQueryApi";

export const useGetPaymentInstruction = (
  agreementId: number,
  paymentInstructionId: number,
) => {
  const api = useApi(AgreementQueryApi);
  return useQuery(agreementKeys.detail(agreementId, paymentInstructionId), () =>
    api.getPaymentInstruction({
      agreementId,
      paymentInstructionId,
    }),
  );
};
