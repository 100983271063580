/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressModel } from './AddressModel';
import {
    AddressModelFromJSON,
    AddressModelFromJSONTyped,
    AddressModelToJSON,
} from './AddressModel';
import type { AgreementFeeModel } from './AgreementFeeModel';
import {
    AgreementFeeModelFromJSON,
    AgreementFeeModelFromJSONTyped,
    AgreementFeeModelToJSON,
} from './AgreementFeeModel';
import type { PaymentFrequencyModel } from './PaymentFrequencyModel';
import {
    PaymentFrequencyModelFromJSON,
    PaymentFrequencyModelFromJSONTyped,
    PaymentFrequencyModelToJSON,
} from './PaymentFrequencyModel';
import type { PaymentStructureModel } from './PaymentStructureModel';
import {
    PaymentStructureModelFromJSON,
    PaymentStructureModelFromJSONTyped,
    PaymentStructureModelToJSON,
} from './PaymentStructureModel';
import type { SeasonalInstalmentListItemModel } from './SeasonalInstalmentListItemModel';
import {
    SeasonalInstalmentListItemModelFromJSON,
    SeasonalInstalmentListItemModelFromJSONTyped,
    SeasonalInstalmentListItemModelToJSON,
} from './SeasonalInstalmentListItemModel';

/**
 * 
 * @export
 * @interface AgreementModel
 */
export interface AgreementModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof AgreementModel
     */
    readonly agreementId: number;
    /**
     * The agreement number
     * @type {string}
     * @memberof AgreementModel
     */
    agreementNumber: string;
    /**
     * The customer name
     * @type {string}
     * @memberof AgreementModel
     */
    customerName: string;
    /**
     * The display name of the product
     * @type {string}
     * @memberof AgreementModel
     */
    productName: string;
    /**
     * The type of lease on a lease agreement
     * @type {string}
     * @memberof AgreementModel
     */
    leaseType?: string;
    /**
     * The current status of the agreement
     * @type {string}
     * @memberof AgreementModel
     */
    status: string;
    /**
     * The code for the status of the agreement
     * @type {string}
     * @memberof AgreementModel
     */
    statusCode: string;
    /**
     * The name of the portfolio to which the agreement belongs
     * @type {string}
     * @memberof AgreementModel
     */
    portfolioName: string;
    /**
     * A read only Id
     * @type {number}
     * @memberof AgreementModel
     */
    readonly portfolioId: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    currentBalance: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    arrearsBalance: string;
    /**
     * 
     * @type {number}
     * @memberof AgreementModel
     */
    numberOfPayments: number;
    /**
     * 
     * @type {PaymentFrequencyModel}
     * @memberof AgreementModel
     */
    paymentFrequency: PaymentFrequencyModel;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    paymentAmount: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    initialRentalPaymentAmount: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof AgreementModel
     */
    inceptionDate: Date;
    /**
     * A format for dates
     * @type {Date}
     * @memberof AgreementModel
     */
    firstPaymentDate: Date;
    /**
     * The actual calculated interest rate
     * @type {number}
     * @memberof AgreementModel
     */
    actualCalculatedInterestRate: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    b2bFundingRate: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    brokerRate: string;
    /**
     * A string representation of a number formatted to 4 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    customerInterestRate: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    totalNetCashPrice: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    vatOnCashPrice: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    totalCashPrice: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    cashDeposit: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    blindDiscount: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    fleetDiscount: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    partExchange: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    totalDeposit: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    balloonPayment: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    balanceFinanced: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    residualValue: string;
    /**
     * A string representation of a number formatted to 2 decimal places with a maximum of 100 and a minimum of 1
     * @type {string}
     * @memberof AgreementModel
     */
    residualPercentage: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    funderIrr: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    customerIrr: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    annualAdministrationFee: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    arrangementFee: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    legalFee: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    facilityFee: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    documentationFee: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    valuationFee: string;
    /**
     * The supplier commission percentage
     * @type {number}
     * @memberof AgreementModel
     */
    supplierCommission: number;
    /**
     * The broker commission percentage
     * @type {number}
     * @memberof AgreementModel
     */
    brokerCommission: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    supplierAmount: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    brokerAmount: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    optionToPurchaseFee: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    landRegistryFee: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    exitFee: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    subsidyPayment: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof AgreementModel
     */
    subsidyDate?: Date;
    /**
     * The location
     * @type {string}
     * @memberof AgreementModel
     */
    queue: string;
    /**
     * The Bank Code
     * @type {string}
     * @memberof AgreementModel
     */
    bankCode: string;
    /**
     * The instalment day
     * @type {number}
     * @memberof AgreementModel
     */
    instalmentDay: number;
    /**
     * The ID of the customer for which the agreement belongs to
     * @type {number}
     * @memberof AgreementModel
     */
    customerId: number;
    /**
     * Whether there is a dd mandate held for the client
     * @type {boolean}
     * @memberof AgreementModel
     */
    ddMandateHeld: boolean;
    /**
     * 
     * @type {AddressModel}
     * @memberof AgreementModel
     */
    address: AddressModel;
    /**
     * The facility risk rating associated to the customer
     * @type {number}
     * @memberof AgreementModel
     */
    facilityRiskRating?: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    setupCosts?: string;
    /**
     * 
     * @type {PaymentStructureModel}
     * @memberof AgreementModel
     */
    paymentStructure?: PaymentStructureModel;
    /**
     * 
     * @type {Array<SeasonalInstalmentListItemModel>}
     * @memberof AgreementModel
     */
    seasonalInstalments?: Array<SeasonalInstalmentListItemModel>;
    /**
     * The VAT reclaim month
     * @type {number}
     * @memberof AgreementModel
     */
    vatReclaimMonth?: number;
    /**
     * Maintainer Name
     * @type {string}
     * @memberof AgreementModel
     */
    maintainerName?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    maintenanceAmount?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    maintenanceCost?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    administrationFee?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    capitalBalance?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    interestBalance?: string;
    /**
     * Denotes the number of days in advance that direct debits are requested
     * @type {number}
     * @memberof AgreementModel
     */
    daysInAdvance: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementModel
     */
    earlyPays: string;
    /**
     * 
     * @type {Array<AgreementFeeModel>}
     * @memberof AgreementModel
     */
    fees?: Array<AgreementFeeModel>;
}

/**
 * Check if a given object implements the AgreementModel interface.
 */
export function instanceOfAgreementModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "agreementId" in value;
    isInstance = isInstance && "agreementNumber" in value;
    isInstance = isInstance && "customerName" in value;
    isInstance = isInstance && "productName" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "statusCode" in value;
    isInstance = isInstance && "portfolioName" in value;
    isInstance = isInstance && "portfolioId" in value;
    isInstance = isInstance && "currentBalance" in value;
    isInstance = isInstance && "arrearsBalance" in value;
    isInstance = isInstance && "numberOfPayments" in value;
    isInstance = isInstance && "paymentFrequency" in value;
    isInstance = isInstance && "paymentAmount" in value;
    isInstance = isInstance && "initialRentalPaymentAmount" in value;
    isInstance = isInstance && "inceptionDate" in value;
    isInstance = isInstance && "firstPaymentDate" in value;
    isInstance = isInstance && "actualCalculatedInterestRate" in value;
    isInstance = isInstance && "b2bFundingRate" in value;
    isInstance = isInstance && "brokerRate" in value;
    isInstance = isInstance && "customerInterestRate" in value;
    isInstance = isInstance && "totalNetCashPrice" in value;
    isInstance = isInstance && "vatOnCashPrice" in value;
    isInstance = isInstance && "totalCashPrice" in value;
    isInstance = isInstance && "cashDeposit" in value;
    isInstance = isInstance && "blindDiscount" in value;
    isInstance = isInstance && "fleetDiscount" in value;
    isInstance = isInstance && "partExchange" in value;
    isInstance = isInstance && "totalDeposit" in value;
    isInstance = isInstance && "balloonPayment" in value;
    isInstance = isInstance && "balanceFinanced" in value;
    isInstance = isInstance && "residualValue" in value;
    isInstance = isInstance && "residualPercentage" in value;
    isInstance = isInstance && "funderIrr" in value;
    isInstance = isInstance && "customerIrr" in value;
    isInstance = isInstance && "annualAdministrationFee" in value;
    isInstance = isInstance && "arrangementFee" in value;
    isInstance = isInstance && "legalFee" in value;
    isInstance = isInstance && "facilityFee" in value;
    isInstance = isInstance && "documentationFee" in value;
    isInstance = isInstance && "valuationFee" in value;
    isInstance = isInstance && "supplierCommission" in value;
    isInstance = isInstance && "brokerCommission" in value;
    isInstance = isInstance && "supplierAmount" in value;
    isInstance = isInstance && "brokerAmount" in value;
    isInstance = isInstance && "optionToPurchaseFee" in value;
    isInstance = isInstance && "landRegistryFee" in value;
    isInstance = isInstance && "exitFee" in value;
    isInstance = isInstance && "subsidyPayment" in value;
    isInstance = isInstance && "queue" in value;
    isInstance = isInstance && "bankCode" in value;
    isInstance = isInstance && "instalmentDay" in value;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "ddMandateHeld" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "daysInAdvance" in value;
    isInstance = isInstance && "earlyPays" in value;

    return isInstance;
}

export function AgreementModelFromJSON(json: any): AgreementModel {
    return AgreementModelFromJSONTyped(json, false);
}

export function AgreementModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agreementId': json['agreementId'],
        'agreementNumber': json['agreementNumber'],
        'customerName': json['customerName'],
        'productName': json['productName'],
        'leaseType': !exists(json, 'leaseType') ? undefined : json['leaseType'],
        'status': json['status'],
        'statusCode': json['statusCode'],
        'portfolioName': json['portfolioName'],
        'portfolioId': json['portfolioId'],
        'currentBalance': json['currentBalance'],
        'arrearsBalance': json['arrearsBalance'],
        'numberOfPayments': json['numberOfPayments'],
        'paymentFrequency': PaymentFrequencyModelFromJSON(json['paymentFrequency']),
        'paymentAmount': json['paymentAmount'],
        'initialRentalPaymentAmount': json['initialRentalPaymentAmount'],
        'inceptionDate': (new Date(json['inceptionDate'])),
        'firstPaymentDate': (new Date(json['firstPaymentDate'])),
        'actualCalculatedInterestRate': json['actualCalculatedInterestRate'],
        'b2bFundingRate': json['b2bFundingRate'],
        'brokerRate': json['brokerRate'],
        'customerInterestRate': json['customerInterestRate'],
        'totalNetCashPrice': json['totalNetCashPrice'],
        'vatOnCashPrice': json['vatOnCashPrice'],
        'totalCashPrice': json['totalCashPrice'],
        'cashDeposit': json['cashDeposit'],
        'blindDiscount': json['blindDiscount'],
        'fleetDiscount': json['fleetDiscount'],
        'partExchange': json['partExchange'],
        'totalDeposit': json['totalDeposit'],
        'balloonPayment': json['balloonPayment'],
        'balanceFinanced': json['balanceFinanced'],
        'residualValue': json['residualValue'],
        'residualPercentage': json['residualPercentage'],
        'funderIrr': json['funderIrr'],
        'customerIrr': json['customerIrr'],
        'annualAdministrationFee': json['annualAdministrationFee'],
        'arrangementFee': json['arrangementFee'],
        'legalFee': json['legalFee'],
        'facilityFee': json['facilityFee'],
        'documentationFee': json['documentationFee'],
        'valuationFee': json['valuationFee'],
        'supplierCommission': json['supplierCommission'],
        'brokerCommission': json['brokerCommission'],
        'supplierAmount': json['supplierAmount'],
        'brokerAmount': json['brokerAmount'],
        'optionToPurchaseFee': json['optionToPurchaseFee'],
        'landRegistryFee': json['landRegistryFee'],
        'exitFee': json['exitFee'],
        'subsidyPayment': json['subsidyPayment'],
        'subsidyDate': !exists(json, 'subsidyDate') ? undefined : (new Date(json['subsidyDate'])),
        'queue': json['queue'],
        'bankCode': json['bankCode'],
        'instalmentDay': json['instalmentDay'],
        'customerId': json['customerId'],
        'ddMandateHeld': json['ddMandateHeld'],
        'address': AddressModelFromJSON(json['address']),
        'facilityRiskRating': !exists(json, 'facilityRiskRating') ? undefined : json['facilityRiskRating'],
        'setupCosts': !exists(json, 'setupCosts') ? undefined : json['setupCosts'],
        'paymentStructure': !exists(json, 'paymentStructure') ? undefined : PaymentStructureModelFromJSON(json['paymentStructure']),
        'seasonalInstalments': !exists(json, 'seasonalInstalments') ? undefined : ((json['seasonalInstalments'] as Array<any>).map(SeasonalInstalmentListItemModelFromJSON)),
        'vatReclaimMonth': !exists(json, 'vatReclaimMonth') ? undefined : json['vatReclaimMonth'],
        'maintainerName': !exists(json, 'maintainerName') ? undefined : json['maintainerName'],
        'maintenanceAmount': !exists(json, 'maintenanceAmount') ? undefined : json['maintenanceAmount'],
        'maintenanceCost': !exists(json, 'maintenanceCost') ? undefined : json['maintenanceCost'],
        'administrationFee': !exists(json, 'administrationFee') ? undefined : json['administrationFee'],
        'capitalBalance': !exists(json, 'capitalBalance') ? undefined : json['capitalBalance'],
        'interestBalance': !exists(json, 'interestBalance') ? undefined : json['interestBalance'],
        'daysInAdvance': json['daysInAdvance'],
        'earlyPays': json['earlyPays'],
        'fees': !exists(json, 'fees') ? undefined : ((json['fees'] as Array<any>).map(AgreementFeeModelFromJSON)),
    };
}

export function AgreementModelToJSON(value?: AgreementModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agreementNumber': value.agreementNumber,
        'customerName': value.customerName,
        'productName': value.productName,
        'leaseType': value.leaseType,
        'status': value.status,
        'statusCode': value.statusCode,
        'portfolioName': value.portfolioName,
        'currentBalance': value.currentBalance,
        'arrearsBalance': value.arrearsBalance,
        'numberOfPayments': value.numberOfPayments,
        'paymentFrequency': PaymentFrequencyModelToJSON(value.paymentFrequency),
        'paymentAmount': value.paymentAmount,
        'initialRentalPaymentAmount': value.initialRentalPaymentAmount,
        'inceptionDate': (value.inceptionDate.toISOString().substring(0,10)),
        'firstPaymentDate': (value.firstPaymentDate.toISOString().substring(0,10)),
        'actualCalculatedInterestRate': value.actualCalculatedInterestRate,
        'b2bFundingRate': value.b2bFundingRate,
        'brokerRate': value.brokerRate,
        'customerInterestRate': value.customerInterestRate,
        'totalNetCashPrice': value.totalNetCashPrice,
        'vatOnCashPrice': value.vatOnCashPrice,
        'totalCashPrice': value.totalCashPrice,
        'cashDeposit': value.cashDeposit,
        'blindDiscount': value.blindDiscount,
        'fleetDiscount': value.fleetDiscount,
        'partExchange': value.partExchange,
        'totalDeposit': value.totalDeposit,
        'balloonPayment': value.balloonPayment,
        'balanceFinanced': value.balanceFinanced,
        'residualValue': value.residualValue,
        'residualPercentage': value.residualPercentage,
        'funderIrr': value.funderIrr,
        'customerIrr': value.customerIrr,
        'annualAdministrationFee': value.annualAdministrationFee,
        'arrangementFee': value.arrangementFee,
        'legalFee': value.legalFee,
        'facilityFee': value.facilityFee,
        'documentationFee': value.documentationFee,
        'valuationFee': value.valuationFee,
        'supplierCommission': value.supplierCommission,
        'brokerCommission': value.brokerCommission,
        'supplierAmount': value.supplierAmount,
        'brokerAmount': value.brokerAmount,
        'optionToPurchaseFee': value.optionToPurchaseFee,
        'landRegistryFee': value.landRegistryFee,
        'exitFee': value.exitFee,
        'subsidyPayment': value.subsidyPayment,
        'subsidyDate': value.subsidyDate === undefined ? undefined : (value.subsidyDate.toISOString().substring(0,10)),
        'queue': value.queue,
        'bankCode': value.bankCode,
        'instalmentDay': value.instalmentDay,
        'customerId': value.customerId,
        'ddMandateHeld': value.ddMandateHeld,
        'address': AddressModelToJSON(value.address),
        'facilityRiskRating': value.facilityRiskRating,
        'setupCosts': value.setupCosts,
        'paymentStructure': PaymentStructureModelToJSON(value.paymentStructure),
        'seasonalInstalments': value.seasonalInstalments === undefined ? undefined : ((value.seasonalInstalments as Array<any>).map(SeasonalInstalmentListItemModelToJSON)),
        'vatReclaimMonth': value.vatReclaimMonth,
        'maintainerName': value.maintainerName,
        'maintenanceAmount': value.maintenanceAmount,
        'maintenanceCost': value.maintenanceCost,
        'administrationFee': value.administrationFee,
        'capitalBalance': value.capitalBalance,
        'interestBalance': value.interestBalance,
        'daysInAdvance': value.daysInAdvance,
        'earlyPays': value.earlyPays,
        'fees': value.fees === undefined ? undefined : ((value.fees as Array<any>).map(AgreementFeeModelToJSON)),
    };
}

