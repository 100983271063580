import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { Button, Stack } from "@mui/material";
import AddAgreementAttachment from "apps/servicing/modules/agreements/components/AddAgreementAttachment";
import AgreementAttachmentsContainer from "apps/servicing/modules/agreements/containers/AgreementAttachmentsContainer";
import CreateDocumentContainer from "apps/servicing/modules/agreements/containers/CreateDocumentContainer";
import useModal from "common/hooks/useModal";
import { ContainedLayout } from "common/layouts";
import FixedLayout from "common/layouts/FixedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { useAgreement } from "common/providers";
import { useTranslation } from "react-i18next";

interface AttachDocumentActionProps {
  agreementId: number;
}

function AttachDocumentAction({ agreementId }: AttachDocumentActionProps) {
  const modal = useModal();
  const { t } = useTranslation("agreements");

  return (
    <>
      <Button variant="contained" onClick={modal.open}>
        {t("agreement_documents.attach_document_button")}
      </Button>

      <AddAgreementAttachment
        agreementId={agreementId}
        open={modal.isOpen}
        onClose={modal.close}
        onSubmit={modal.close}
      />
    </>
  );
}

export default function DocumentsView() {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();

  return (
    <ViewLayout
      title={t("agreement_documents.heading")}
      action={
        <Stack direction="row" spacing={3}>
          <AccessControl
            allowedPermissions={["servicing:agreement-documents:manage"]}
          >
            <AttachDocumentAction agreementId={agreement.id} />
          </AccessControl>
          <AccessControl
            allowedPermissions={["servicing:letter-generation:manage"]}
          >
            <CreateDocumentContainer />
          </AccessControl>
        </Stack>
      }
    >
      <FixedLayout>
        <ContainedLayout>
          <AgreementAttachmentsContainer agreementId={agreement.id} />
        </ContainedLayout>
      </FixedLayout>
    </ViewLayout>
  );
}
