import { AlertDialog, Loader } from "@ldms/mui-sdk/templates";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import * as paymentIntructionKeys from "api/agreements/payment-instructions/keys";
import {
  AddPaymentInstructionFormDialog,
  AddPaymentInstructionFormValues,
} from "apps/servicing/modules/agreements/components";
import { usePartialMutate, useResponseError } from "common/hooks";
import { useAgreement, useApi } from "common/providers";
import { AgreementCommandApi, AgreementQueryApi } from "generated/core/apis";
import { PayeesListItemModel } from "generated/core/models";
import { BaseSyntheticEvent, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import errorHandler from "support/error-handler";
import useSWR from "swr";

interface FormDialogFallbackProps {
  onClose(event?: BaseSyntheticEvent): void;
  title: string;
}

function FormDialogFallback({
  onClose,
  title,
}: FormDialogFallbackProps): ReactElement {
  const { t } = useTranslation(["agreements", "common"]);

  return (
    <>
      <DialogTitle id="add-payment-instruction-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("common:cancel")}
        </Button>
        <Button color="primary" disabled>
          {t("agreements:payments.add_payment_instruction.submit_button")}
        </Button>
      </DialogActions>
    </>
  );
}

interface AddPaymentInstructionContainerProps {
  agreementId: number;
  onSuccess(): void;
  onClose(): void;
  open: boolean;
}

export default function AddPaymentInstructionContainer({
  agreementId,
  onClose,
  onSuccess,
  open,
}: AddPaymentInstructionContainerProps): ReactElement {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();
  const agreementCommandApi = useApi(AgreementCommandApi);
  const agreementQueryApi = useApi(AgreementQueryApi);
  const partialMutate = usePartialMutate();
  const error = useResponseError();
  const payees = useSWR(
    open ? ["/agreement/:id/payment-instructions/payees", agreement.id] : null,
    () => agreementQueryApi.listPayees({ agreementId: agreement.id }),
  );
  const onCloseErrorDialog = (): void => error.reset();

  const onSubmit = async (
    formValues: AddPaymentInstructionFormValues,
  ): Promise<void> => {
    try {
      await agreementCommandApi.createDirectDebit({
        agreementId,
        createDirectDebitModel: {
          day: formValues.day,
          schedule: formValues.type,
          clientId: formValues.clientId,
          startDate: formValues.startDate,
          endDate: formValues.endDate,
          amount: formValues.amount?.toFixed(2),
          signatureDate: formValues.signatureDate,
        },
      });
      onSuccess();
      partialMutate(paymentIntructionKeys.all(agreementId));
    } catch (response) {
      error.setError((await errorHandler(response)).code);
    }
  };

  return (
    <>
      <AlertDialog
        content={error.message}
        labels={{ close: t("common:alert.close") }}
        onClose={onCloseErrorDialog}
        open={Boolean(error.message)}
        title={t("payments.add_payment_instruction.error_title")}
      />

      <Dialog
        onClose={onClose}
        aria-labelledby="add-payment-instruction-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <Loader
          fallback={
            <FormDialogFallback
              onClose={onClose}
              title={t(
                "payments.add_payment_instruction.add_payment_instruction_title",
              )}
            />
          }
          ready={Boolean(payees.data)}
          render={(): ReactElement => (
            <AddPaymentInstructionFormDialog
              instalmentDay={agreement.data?.instalmentDay}
              onClose={onClose}
              onSubmit={onSubmit}
              payees={payees.data as PayeesListItemModel[]}
            />
          )}
        />
      </Dialog>
    </>
  );
}
