/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PayeesListItemBankAccountModel } from './PayeesListItemBankAccountModel';
import {
    PayeesListItemBankAccountModelFromJSON,
    PayeesListItemBankAccountModelFromJSONTyped,
    PayeesListItemBankAccountModelToJSON,
} from './PayeesListItemBankAccountModel';

/**
 * The list of payees and their associated bank details
 * @export
 * @interface PayeesListItemModel
 */
export interface PayeesListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof PayeesListItemModel
     */
    readonly clientId: number;
    /**
     * The contact type in reference to the client
     * @type {string}
     * @memberof PayeesListItemModel
     */
    contactType: string;
    /**
     * The name of the payee
     * @type {string}
     * @memberof PayeesListItemModel
     */
    name: string;
    /**
     * 
     * @type {PayeesListItemBankAccountModel}
     * @memberof PayeesListItemModel
     */
    bankAccount: PayeesListItemBankAccountModel;
    /**
     * A format for dates
     * @type {Date}
     * @memberof PayeesListItemModel
     */
    earliestMandateStartDate: Date;
}

/**
 * Check if a given object implements the PayeesListItemModel interface.
 */
export function instanceOfPayeesListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "contactType" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "bankAccount" in value;
    isInstance = isInstance && "earliestMandateStartDate" in value;

    return isInstance;
}

export function PayeesListItemModelFromJSON(json: any): PayeesListItemModel {
    return PayeesListItemModelFromJSONTyped(json, false);
}

export function PayeesListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayeesListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'contactType': json['contactType'],
        'name': json['name'],
        'bankAccount': PayeesListItemBankAccountModelFromJSON(json['bankAccount']),
        'earliestMandateStartDate': (new Date(json['earliestMandateStartDate'])),
    };
}

export function PayeesListItemModelToJSON(value?: PayeesListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactType': value.contactType,
        'name': value.name,
        'bankAccount': PayeesListItemBankAccountModelToJSON(value.bankAccount),
        'earliestMandateStartDate': (value.earliestMandateStartDate.toISOString().substring(0,10)),
    };
}

