import { AlertDialog } from "@ldms/mui-sdk/templates";
import { MenuItem } from "@mui/material";
import { useUpdateChargeTypeActiveStatus } from "api/charge-types/status";
import { UpdateChargeTypeActiveStatusModel } from "generated/core/models";
import { useTranslation } from "react-i18next";

interface ActivateChargeContainerProps {
  chargeCode: string;
  active: boolean;
  closeMenu: () => void;
}

const ActivateChargeContainer = ({
  chargeCode,
  active,
  closeMenu,
}: Readonly<ActivateChargeContainerProps>) => {
  const { t } = useTranslation("servicing");

  const updateCharge = useUpdateChargeTypeActiveStatus(chargeCode, {
    onSuccess: closeMenu,
  });

  const handleAlertClose = () => {
    closeMenu();
    updateCharge.reset();
  };

  const onSubmit = async () => {
    const updateChargeTypeActiveModel: UpdateChargeTypeActiveStatusModel = {
      isActive: !active,
    };
    await updateCharge.execute(updateChargeTypeActiveModel);
  };

  const menuItem = !active
    ? t("charges.list_charges.activate_label")
    : t("charges.list_charges.deactivate_label");

  return (
    <>
      <MenuItem key="activate-charge" onClick={onSubmit}>
        {menuItem}
      </MenuItem>
      <AlertDialog
        title={t("common:error.title")}
        content={updateCharge.error?.message}
        labels={{ close: t("common:alert.close") }}
        onClose={handleAlertClose}
        open={Boolean(updateCharge.error?.message)}
      />
    </>
  );
};

export default ActivateChargeContainer;
