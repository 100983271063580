import { Loader } from "@ldms/mui-sdk/templates";
import { Box, CircularProgress, Typography } from "@mui/material";
import * as paymentInstructionKeys from "api/agreements/payment-instructions/keys";
import { PaymentInstructionsList } from "apps/servicing/modules/agreements/components";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import ListLayout from "common/layouts/ListLayout";
import { useApi } from "common/providers";
import { AgreementQueryApi } from "generated/core/apis";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

interface AgreementPaymentInstructionsProps {
  agreementId: number;
}

export default function AgreementPaymentInstructions({
  agreementId,
}: AgreementPaymentInstructionsProps): ReactElement {
  const appConfig = useAppConfiguration();
  const { t } = useTranslation(["agreements", "common"]);
  const agreementQueryApi = useApi(AgreementQueryApi);
  const paymentInstructions = useSWR(
    paymentInstructionKeys.list(agreementId),
    () => agreementQueryApi.listPaymentInstructions({ agreementId }),
  );

  return (
    <>
      <ListLayout>
        <Loader
          fallback={
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress />
            </Box>
          }
          ready={Boolean(paymentInstructions.data ?? paymentInstructions.error)}
          render={() => {
            if (paymentInstructions.error || !paymentInstructions.data) {
              return (
                <Typography
                  aria-label={t("common:error.default")}
                  color="error"
                  role="alert"
                >
                  {t("common:error.default")}
                </Typography>
              );
            }

            return (
              <PaymentInstructionsList
                agreementId={agreementId}
                data={paymentInstructions.data.map((instruction) => ({
                  ...instruction,
                  href: `${appConfig.appRoutes.servicing}/agreements/${instruction.agreementId}/payment-instructions/${instruction.id}`,
                }))}
                loading={paymentInstructions.isValidating}
              />
            );
          }}
        />
      </ListLayout>
    </>
  );
}
