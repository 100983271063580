/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (charge types)
 * API for managing Charge Types within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AllocationTypeModel } from './AllocationTypeModel';
import {
    AllocationTypeModelFromJSON,
    AllocationTypeModelFromJSONTyped,
    AllocationTypeModelToJSON,
} from './AllocationTypeModel';
import type { SubTypeModel } from './SubTypeModel';
import {
    SubTypeModelFromJSON,
    SubTypeModelFromJSONTyped,
    SubTypeModelToJSON,
} from './SubTypeModel';

/**
 * Charge type list item
 * @export
 * @interface ChargeTypeListItemModel
 */
export interface ChargeTypeListItemModel {
    /**
     * The charge type code
     * @type {string}
     * @memberof ChargeTypeListItemModel
     */
    code: string;
    /**
     * Charge type name
     * @type {string}
     * @memberof ChargeTypeListItemModel
     */
    name: string;
    /**
     * 
     * @type {SubTypeModel}
     * @memberof ChargeTypeListItemModel
     */
    subType: SubTypeModel;
    /**
     * 
     * @type {AllocationTypeModel}
     * @memberof ChargeTypeListItemModel
     */
    allocationType: AllocationTypeModel;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ChargeTypeListItemModel
     */
    amount: string;
    /**
     * Indicates whether the charge type is currently active
     * @type {boolean}
     * @memberof ChargeTypeListItemModel
     */
    isActive: boolean;
    /**
     * Name of user who created the charge type
     * @type {string}
     * @memberof ChargeTypeListItemModel
     */
    createdBy: string;
}

/**
 * Check if a given object implements the ChargeTypeListItemModel interface.
 */
export function instanceOfChargeTypeListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "subType" in value;
    isInstance = isInstance && "allocationType" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "isActive" in value;
    isInstance = isInstance && "createdBy" in value;

    return isInstance;
}

export function ChargeTypeListItemModelFromJSON(json: any): ChargeTypeListItemModel {
    return ChargeTypeListItemModelFromJSONTyped(json, false);
}

export function ChargeTypeListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChargeTypeListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'name': json['name'],
        'subType': SubTypeModelFromJSON(json['subType']),
        'allocationType': AllocationTypeModelFromJSON(json['allocationType']),
        'amount': json['amount'],
        'isActive': json['isActive'],
        'createdBy': json['createdBy'],
    };
}

export function ChargeTypeListItemModelToJSON(value?: ChargeTypeListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
        'subType': SubTypeModelToJSON(value.subType),
        'allocationType': AllocationTypeModelToJSON(value.allocationType),
        'amount': value.amount,
        'isActive': value.isActive,
        'createdBy': value.createdBy,
    };
}

