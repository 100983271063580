/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (charge types)
 * API for managing Charge Types within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChargeTypeDetailModel,
  UpdateChargeTypeActiveStatusModel,
  UpdateChargeTypeDetailsModel,
} from '../models/index';
import {
    ChargeTypeDetailModelFromJSON,
    ChargeTypeDetailModelToJSON,
    UpdateChargeTypeActiveStatusModelFromJSON,
    UpdateChargeTypeActiveStatusModelToJSON,
    UpdateChargeTypeDetailsModelFromJSON,
    UpdateChargeTypeDetailsModelToJSON,
} from '../models/index';

export interface CreateChargeTypeRequest {
    chargeTypeDetailModel?: ChargeTypeDetailModel;
}

export interface UpdateChargeTypeActiveStatusRequest {
    code: string;
    updateChargeTypeActiveStatusModel?: UpdateChargeTypeActiveStatusModel;
}

export interface UpdateChargeTypeDetailsRequest {
    code: string;
    updateChargeTypeDetailsModel?: UpdateChargeTypeDetailsModel;
}

/**
 * 
 */
export class ChargeTypeCommandApi extends runtime.BaseAPI {

    /**
     * Create a Charge Type
     */
    async createChargeTypeRaw(requestParameters: CreateChargeTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["charge-types:manage"]);
        }

        const response = await this.request({
            path: `/charge-types`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChargeTypeDetailModelToJSON(requestParameters.chargeTypeDetailModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a Charge Type
     */
    async createChargeType(requestParameters: CreateChargeTypeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createChargeTypeRaw(requestParameters, initOverrides);
    }

    /**
     * Update Charge Type Active Status
     */
    async updateChargeTypeActiveStatusRaw(requestParameters: UpdateChargeTypeActiveStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling updateChargeTypeActiveStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["charge-types:manage"]);
        }

        const response = await this.request({
            path: `/charge-types/{code}/status`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateChargeTypeActiveStatusModelToJSON(requestParameters.updateChargeTypeActiveStatusModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Charge Type Active Status
     */
    async updateChargeTypeActiveStatus(requestParameters: UpdateChargeTypeActiveStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateChargeTypeActiveStatusRaw(requestParameters, initOverrides);
    }

    /**
     * Update Charge Type Details
     */
    async updateChargeTypeDetailsRaw(requestParameters: UpdateChargeTypeDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling updateChargeTypeDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["charge-types:manage"]);
        }

        const response = await this.request({
            path: `/charge-types/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateChargeTypeDetailsModelToJSON(requestParameters.updateChargeTypeDetailsModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Charge Type Details
     */
    async updateChargeTypeDetails(requestParameters: UpdateChargeTypeDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateChargeTypeDetailsRaw(requestParameters, initOverrides);
    }

}
