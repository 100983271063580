import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { Box, Chip, Typography } from "@mui/material";
import { useGetPaymentInstruction } from "api/agreements/payment-instructions/getPaymentInstruction";
import CancelPaymentInstructionActionContainer from "apps/servicing/modules/agreements/containers/CancelPaymentInstructionActionContainer";
import PaymentInstructionDetails from "apps/servicing/modules/agreements/containers/PaymentInstructionDetails";
import { withModifiableAccess } from "apps/servicing/modules/agreements/hocs/withModifiableAccess";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import ContainedLayout from "common/layouts/ContainedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { useAgreement } from "common/providers";
import { useTranslation } from "react-i18next";

interface PaymentInstructionViewProps {
  paymentInstructionId: number;
}

function PaymentInstructionView({
  paymentInstructionId,
}: PaymentInstructionViewProps) {
  const agreement = useAgreement();
  const paymentInstruction = useGetPaymentInstruction(
    agreement.id,
    paymentInstructionId,
  );
  const { t } = useTranslation("agreements");
  const appConfig = useAppConfiguration();

  const isActive = () => paymentInstruction.data?.details.active;

  return (
    <ViewLayout
      title={
        paymentInstruction.data && !isActive() ? (
          <Box display="flex">
            <Typography component="h1" variant="h4" marginRight={1}>
              {t("payment_instruction.heading")}
            </Typography>
            <Chip
              size="small"
              label={t("payment_instruction.inactive_label")}
            />
          </Box>
        ) : (
          t("payment_instruction.heading")
        )
      }
      breadcrumbs={[
        {
          href: `${appConfig.appRoutes.servicing}/agreements/${agreement.id}/payment-instructions`,
          label: t("breadcrumbs.payment_instructions_link"),
        },
      ]}
      action={
        isActive() && (
          <AccessControl
            allowedPermissions={["servicing:payment-instructions:manage"]}
          >
            <CancelPaymentInstructionActionContainer
              agreementId={agreement.id}
              paymentInstructionId={paymentInstructionId}
            />
          </AccessControl>
        )
      }
    >
      <ContainedLayout>
        <PaymentInstructionDetails
          paymentInstructionId={paymentInstructionId}
          agreementId={agreement.id}
        />
      </ContainedLayout>
    </ViewLayout>
  );
}

export default withModifiableAccess(PaymentInstructionView);
