import { AmountField, Form } from "@ldms/mui-sdk/forms";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCreateChargeType } from "api/charge-types";
import { ControlledTextField } from "common/components";
import { useYupResolver } from "common/hooks";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import {
  AllocationTypeModel,
  NetEffectModel,
  RecoverableTypeModel,
  SubTypeModel,
} from "generated/core/models";
import { ReactElement } from "react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  Resolver,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";

interface ChargeTypeDetailFieldSet {
  name: string;
  subType: SubTypeModel | "";
  allocationType: AllocationTypeModel | "";
  netEffect: NetEffectModel | "";
  recoverableType: RecoverableTypeModel | "";
  amount: number;
  isCollectedByDirectDebit: boolean;
  isTaxable: boolean;
  isIncludedOnInvoice: boolean;
  isActive: boolean;
}

const transformField = (v: string, o: string): string | null | undefined => {
  return o === "" ? undefined : v;
};

const useAddChargeResolver = (): Resolver<ChargeTypeDetailFieldSet> => {
  const { t } = useTranslation("servicing");

  const chargeNameLabel = "charges.add_charge.charge_name_label";
  const amountLabel = "charges.add_charge.amount_label";

  return useYupResolver<ChargeTypeDetailFieldSet>((yup) =>
    yup.object().shape({
      name: yup
        .string()
        .isRequired(t(chargeNameLabel))
        .maxCharacters(50, t(chargeNameLabel)),
      subType: yup
        .string()
        .isRequired(t("charges.add_charge.charge_type_label")),
      allocationType: yup
        .string()
        .isRequired(t("charges.add_charge.allocation_type_label")),
      netEffect: yup
        .string()
        .isRequired(t("charges.add_charge.net_effect_label")),
      recoverableType: yup.string().when("subType", {
        is: SubTypeModel.Charge,
        then: yup
          .string()
          .isRequired(t("charges.add_charge.recoverable_label")),
      }),
      amount: yup
        .number(t(amountLabel))
        .transform(transformField)
        .isRequired(t(amountLabel))
        .minAmount(0, t(amountLabel))
        .maxAmount(999999.99, t(amountLabel)),
      isCollectedByDirectDebit: yup.boolean(),
      isTaxable: yup.boolean(),
      isIncludedOnInvoice: yup.boolean(),
      isActive: yup.boolean(),
    }),
  );
};

interface ChargeCheckboxProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  label: string;
}

export function ChargeCheckbox<TFieldValues extends FieldValues>({
  control,
  label,
  name,
}: Readonly<ChargeCheckboxProps<TFieldValues>>): ReactElement {
  const { t } = useTranslation("servicing");

  return (
    <Grid item sm={12}>
      <FormControlLabel
        label={String(t(label))}
        control={
          <Controller
            control={control}
            name={name}
            render={({ field }): ReactElement => (
              <Checkbox
                {...field}
                checked={field.value}
                onChange={(e): void => field.onChange(e.target.checked)}
              />
            )}
          />
        }
      />
    </Grid>
  );
}

interface ChargeControlledFieldProps<
  TFieldValues extends FieldValues,
  TOptions = Record<string, string>,
> {
  error?: string;
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  label: string;
  options: TOptions;
}

function ChargeControlledField<
  TFieldValues extends FieldValues,
  TOptions extends Record<string, string> = Record<string, string>,
>({
  control,
  options,
  error,
  label,
  name,
}: Readonly<ChargeControlledFieldProps<TFieldValues, TOptions>>): ReactElement {
  const { t } = useTranslation("servicing");

  return (
    <Grid item sm={12}>
      <ControlledTextField
        select
        id={name}
        name={name}
        label={t(label)}
        helperText={error}
        error={error}
        SelectProps={{ displayEmpty: true }}
        control={control}
        required
      >
        <MenuItem value="">
          <i>{t("common:please_select")}</i>
        </MenuItem>

        {Object.values<string>(options).map((key) => (
          <MenuItem key={key} value={key}>
            {key}
          </MenuItem>
        ))}
      </ControlledTextField>
    </Grid>
  );
}

export default function AddChargeContainer(): ReactElement {
  const { t } = useTranslation("servicing");
  const resolver = useAddChargeResolver();
  const navigate = useNavigate();
  const appConfig = useAppConfiguration();

  const addCharge = useCreateChargeType({
    onSuccess: () => navigate(".."),
  });

  const onSubmit = async (data: ChargeTypeDetailFieldSet): Promise<void> => {
    addCharge.reset();
    await addCharge.execute({
      ...data,
      allocationType: data.allocationType as AllocationTypeModel,
      netEffect: data.netEffect as NetEffectModel,
      recoverableType:
        data.subType === SubTypeModel.Discount
          ? undefined
          : (data.recoverableType as RecoverableTypeModel),
      subType: data.subType as SubTypeModel,
      amount: data.amount.toFixed(2),
      isCollectedByDirectDebit:
        data.subType === SubTypeModel.Discount
          ? undefined
          : data.isCollectedByDirectDebit,
      isIncludedOnInvoice:
        data.subType === SubTypeModel.Discount
          ? undefined
          : data.isIncludedOnInvoice,
    });
  };

  const responseError = new Map([
    ["charge_type_exists", t("charges.add_charge.charge_name_exists_message")],
    [
      "invalid_charge_type",
      t("charges.add_charge.recoverable_required_charge_message"),
    ],
  ]);

  return (
    <Form
      label={t("charges.add_charge.title_label")}
      onSubmit={onSubmit}
      resolver={resolver}
      defaultValues={{
        name: "",
        subType: "",
        allocationType: "",
        netEffect: "",
        recoverableType: "",
        amount: undefined,
        isCollectedByDirectDebit: true,
        isTaxable: false,
        isIncludedOnInvoice: false,
        isActive: false,
      }}
    >
      {(form) => {
        const formWatch = form.watch();

        return (
          <Container maxWidth="sm">
            <Paper>
              <Stack gap={2} padding={2}>
                <Grid container spacing={2} rowSpacing={0.5}>
                  <Grid item sm={12}>
                    <TextField
                      {...form.register("name")}
                      error={Boolean(form.formState.errors.name)}
                      helperText={form.formState.errors.name?.message}
                      label={t("charges.add_charge.charge_name_label")}
                      required
                    />
                  </Grid>
                  <ChargeControlledField
                    name="subType"
                    label="charges.add_charge.charge_type_label"
                    control={form.control}
                    options={SubTypeModel}
                    error={form.formState.errors?.subType?.message}
                  />

                  <ChargeControlledField
                    name="allocationType"
                    label="charges.add_charge.allocation_type_label"
                    control={form.control}
                    options={AllocationTypeModel}
                    error={form.formState.errors?.allocationType?.message}
                  />

                  <ChargeControlledField
                    name="netEffect"
                    label="charges.add_charge.net_effect_label"
                    control={form.control}
                    options={NetEffectModel}
                    error={form.formState.errors?.netEffect?.message}
                  />

                  {formWatch.subType === SubTypeModel.Charge && (
                    <ChargeControlledField
                      name="recoverableType"
                      label="charges.add_charge.recoverable_label"
                      control={form.control}
                      options={RecoverableTypeModel}
                      error={form.formState.errors?.recoverableType?.message}
                    />
                  )}

                  <Grid item sm={12}>
                    <AmountField
                      control={form.control}
                      label={t("charges.add_charge.amount_label")}
                      name="amount"
                      required
                      error={Boolean(form.formState.errors.amount?.message)}
                      helperText={form.formState.errors.amount?.message}
                    />
                  </Grid>

                  {formWatch.subType === SubTypeModel.Charge && (
                    <ChargeCheckbox
                      control={form.control}
                      label="charges.add_charge.collect_by_dd_label"
                      name="isCollectedByDirectDebit"
                    />
                  )}

                  <ChargeCheckbox
                    control={form.control}
                    label="charges.add_charge.vat_applicable_label"
                    name="isTaxable"
                  />

                  {formWatch.subType === SubTypeModel.Charge && (
                    <ChargeCheckbox
                      control={form.control}
                      label="charges.add_charge.include_on_invoices_label"
                      name="isIncludedOnInvoice"
                    />
                  )}

                  <ChargeCheckbox
                    control={form.control}
                    label="charges.add_charge.active_label"
                    name="isActive"
                  />
                </Grid>

                {addCharge.error && (
                  <Box>
                    <Typography color="error">
                      {responseError.get(addCharge.error.code) ??
                        t("common:error.default")}
                    </Typography>
                  </Box>
                )}

                <Box display="flex" justifyContent="flex-end" gap={1}>
                  <Button
                    key="common:cancel"
                    component={RouterLink}
                    to={`${appConfig.appRoutes.servicing}/settings/charges`}
                    color="primary"
                  >
                    {t("common:cancel")}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={addCharge.isExecuting}
                  >
                    {t("common:submit")}
                  </Button>
                </Box>
              </Stack>
            </Paper>
          </Container>
        );
      }}
    </Form>
  );
}
