import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import * as keys from "api/charge-types/keys";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import { ChargeTypeCommandApi } from "generated/core/apis";
import { UpdateChargeTypeActiveStatusModel } from "generated/core/models";

export const useUpdateChargeTypeActiveStatus = (
  code: string,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(ChargeTypeCommandApi);
  const partialMutate = usePartialMutate();

  return useCommand<UpdateChargeTypeActiveStatusModel, void, ResponseError>(
    (model) =>
      api.updateChargeTypeActiveStatus({
        code: code,
        updateChargeTypeActiveStatusModel: model,
      }),
    {
      ...options,
      onSuccess: () => {
        partialMutate(keys.all());
        options.onSuccess?.();
      },
    },
  );
};
