/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (charge types)
 * API for managing Charge Types within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Charge type history details
 * @export
 * @interface ChargeTypeHistoryListItemModel
 */
export interface ChargeTypeHistoryListItemModel {
    /**
     * A format for dates
     * @type {Date}
     * @memberof ChargeTypeHistoryListItemModel
     */
    modifiedDate: Date;
    /**
     * Name of user who modified the charge type
     * @type {string}
     * @memberof ChargeTypeHistoryListItemModel
     */
    modifiedBy: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ChargeTypeHistoryListItemModel
     */
    amount: string;
}

/**
 * Check if a given object implements the ChargeTypeHistoryListItemModel interface.
 */
export function instanceOfChargeTypeHistoryListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "modifiedDate" in value;
    isInstance = isInstance && "modifiedBy" in value;
    isInstance = isInstance && "amount" in value;

    return isInstance;
}

export function ChargeTypeHistoryListItemModelFromJSON(json: any): ChargeTypeHistoryListItemModel {
    return ChargeTypeHistoryListItemModelFromJSONTyped(json, false);
}

export function ChargeTypeHistoryListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChargeTypeHistoryListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modifiedDate': (new Date(json['modifiedDate'])),
        'modifiedBy': json['modifiedBy'],
        'amount': json['amount'],
    };
}

export function ChargeTypeHistoryListItemModelToJSON(value?: ChargeTypeHistoryListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modifiedDate': (value.modifiedDate.toISOString().substring(0,10)),
        'modifiedBy': value.modifiedBy,
        'amount': value.amount,
    };
}

