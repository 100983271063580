/* tslint:disable */
/* eslint-disable */
/**
 * Engage Gateway (Profile)
 * API for managing Profiles within Engage Gateway
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApplicationModel } from './ApplicationModel';
import {
    ApplicationModelFromJSON,
    ApplicationModelFromJSONTyped,
    ApplicationModelToJSON,
} from './ApplicationModel';

/**
 * 
 * @export
 * @interface EnvironmentModel
 */
export interface EnvironmentModel {
    /**
     * Denotes whether this environment is a production environment
     * @type {boolean}
     * @memberof EnvironmentModel
     */
    production?: boolean;
    /**
     * 
     * @type {{ [key: string]: ApplicationModel; }}
     * @memberof EnvironmentModel
     */
    applications?: { [key: string]: ApplicationModel; };
}

/**
 * Check if a given object implements the EnvironmentModel interface.
 */
export function instanceOfEnvironmentModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EnvironmentModelFromJSON(json: any): EnvironmentModel {
    return EnvironmentModelFromJSONTyped(json, false);
}

export function EnvironmentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvironmentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'production': !exists(json, 'production') ? undefined : json['production'],
        'applications': !exists(json, 'applications') ? undefined : (mapValues(json['applications'], ApplicationModelFromJSON)),
    };
}

export function EnvironmentModelToJSON(value?: EnvironmentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'production': value.production,
        'applications': value.applications === undefined ? undefined : (mapValues(value.applications, ApplicationModelToJSON)),
    };
}

