import * as agreementKeys from "api/agreements/keys";

export const all = (agreementId: number) => [
  ...agreementKeys.detail(agreementId),
  "payment-instructions",
];

export const list = (agreementId: number) => [...all(agreementId), "list"];

export const detail = (agreementId: number, paymentInstructionId: number) => [
  ...all(agreementId),
  "detail",
  paymentInstructionId,
];
