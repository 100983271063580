import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useFindCustomerAndAgreements } from "api/tasks/findCustomersAndAgreements/findCustomerAndAgreements";
import { debounce } from "lodash";
import { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export type CustomerAndAgreementsFilterProps = {
  type: string;
  name: string;
  id: number;
};

const CustomerAndAgreementsFilter = ({
  onChange,
  value,
  label,
}: {
  onChange: (newValue: CustomerAndAgreementsFilterProps | null) => void;
  value: CustomerAndAgreementsFilterProps | null;
  label: string;
}) => {
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(["start"]);

  const customersAndAgreements = useFindCustomerAndAgreements({
    query,
    enabled: query.trim().length > 0,
  });

  const onQueryChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setQuery(event.target.value);
  };

  const handleQueryChange = useMemo(() => debounce(onQueryChange, 300), []);

  const customerOptions = (customersAndAgreements.data?.customers || []).map(
    (customer) => ({
      type: "Customer",
      name: customer.name,
      id: customer.id,
    }),
  );

  const agreementOptions = (customersAndAgreements.data?.agreements || []).map(
    (agreement) => ({
      type: "Agreement",
      name: agreement.agreementNumber,
      id: agreement.id,
    }),
  );

  const options = [...customerOptions, ...agreementOptions];

  const handleClose = () => {
    setQuery("");
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    return handleQueryChange.cancel;
  }, [handleQueryChange]);

  const loading = open && customersAndAgreements.isValidating;

  return (
    <Autocomplete
      fullWidth
      isOptionEqualToValue={(option, value) => option.name === value.name}
      options={options}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      value={value}
      loading={loading}
      id="customer-and-agreements"
      groupBy={(option) => option.type}
      noOptionsText={t("no_options_text")}
      getOptionLabel={(option) => option.name}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      sx={{ width: 300 }}
      renderInput={({ inputProps, ...params }) => (
        <TextField
          {...params}
          label={t(label)}
          name="customerAndAgreements"
          onChange={handleQueryChange}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            ...inputProps,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                    sx={{ marginRight: "35px" }}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          margin="none"
        />
      )}
    />
  );
};

export { CustomerAndAgreementsFilter };
