import { useFormat } from "@ldms/mui-sdk/formatting";
import {
  ListItemButton,
  ListItemText,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
} from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import { useLocale } from "common/hooks";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

export type PaymentInstruction = {
  id: number;
  reference?: string;
  agreementId: number;
  schedule: string;
  payee: string;
  method: string;
  amount: string;
  day: number;
  endDate?: Date;
  active: boolean;
  href: string;
  signatureDate?: Date;
};

interface PaymentInstructionsListProps {
  agreementId: number;
  data: PaymentInstruction[];
  loading: boolean;
}

export default function PaymentInstructionsList({
  agreementId,
  data,
  loading,
}: PaymentInstructionsListProps): ReactElement {
  const { t } = useTranslation("agreements");
  const locale = useLocale();
  const { formatAmount } = useFormat();
  const appConfig = useAppConfiguration();

  const tableHeaders: string[] = [
    t("payments.list.type_label"),
    t("payments.list.payee_label"),
    t("payments.list.method_label"),
    t("payments.list.amount_label"),
    t("payments.list.day_label"),
    t("payments.list.end_date_label"),
    t("payments.list.signature_date_label"),
    t("payments.list.status_label"),
  ];

  return (
    <>
      <Table
        aria-label={t("payments.payments_instructions_page_title")}
        size="small"
        stickyHeader
      >
        <LoadableTableHead headings={tableHeaders} loading={loading} />
        <TableBody>
          {data.map((item) => {
            const sxActive: SxProps<Theme> = (theme) =>
              !item.active ? { color: theme.palette.text.disabled } : {};

            return (
              <TableRow key={item.id} hover>
                <TableCell sx={sxActive} padding="none">
                  <ListItemButton
                    component={RouterLink}
                    dense
                    to={`${appConfig.appRoutes.servicing}/agreements/${agreementId}/payment-instructions/${item.id}`}
                  >
                    <ListItemText
                      primary={item.schedule}
                      primaryTypographyProps={{ color: "primary" }}
                    />
                  </ListItemButton>
                </TableCell>
                <TableCell sx={sxActive}>{item.payee}</TableCell>
                <TableCell sx={sxActive}>{item.method}</TableCell>
                <TableCell sx={sxActive}>{formatAmount(item.amount)}</TableCell>
                <TableCell sx={sxActive}>{item.day}</TableCell>
                <TableCell sx={sxActive}>
                  {item.endDate && locale.formatDate(new Date(item.endDate))}
                </TableCell>

                <TableCell sx={sxActive}>
                  {item.signatureDate &&
                    locale.formatDate(new Date(item.signatureDate))}
                </TableCell>

                <TableCell sx={sxActive}>
                  {item.active
                    ? t("payments.list.active_label")
                    : t("payments.list.inactive_label")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {data.length === 0 && <NoResults />}
    </>
  );
}
