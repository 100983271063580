import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { useGetChargeTypeDetails } from "api/charge-types/getChargeTypeDetails";
import EditChargeContainer from "apps/servicing/modules/settings/containers/EditChargeTypeContainer";
import { Loader, QueryError, YesNoValue } from "common/components";
import Surface from "common/components/Surface";
import { useLocale } from "common/hooks";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface ChargeTypeDetailsContainerProps {
  chargeCode: string;
}

export default function ChargeTypeDetailsContainer({
  chargeCode,
}: Readonly<ChargeTypeDetailsContainerProps>): ReactElement {
  const { t } = useTranslation("servicing");
  const chargeDetails = useGetChargeTypeDetails(chargeCode);

  const locale = useLocale();
  const { formatAmount } = useFormat();

  return (
    <Loader
      ready={Boolean(chargeDetails.data ?? chargeDetails.error)}
      render={(): ReactElement => {
        if (chargeDetails.error || !chargeDetails.data) {
          return <QueryError onRetry={chargeDetails.refetch} />;
        }

        return (
          <Surface
            title={t("charge_details.details.current_details_heading")}
            action={
              <EditChargeContainer
                chargeDetails={{
                  ...chargeDetails.data,
                  amount: Number(chargeDetails.data.amount),
                }}
                chargeCode={chargeCode}
              />
            }
          >
            <DescriptionList
              label={t("charge_details.details.current_details_heading")}
            >
              <DescriptionList.Item
                label={t("charge_details.details.charge_name_label")}
              >
                {chargeDetails.data.name}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("charge_details.details.type_label")}
              >
                {chargeDetails.data.subType}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("charge_details.details.allocation_type_label")}
              >
                {chargeDetails.data.allocationType}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("charge_details.details.recoverable_label")}
              >
                {chargeDetails.data.recoverableType}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("charge_details.details.net_effect_label")}
              >
                {chargeDetails.data.netEffect}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("charge_details.details.amount_label")}
              >
                {formatAmount(chargeDetails.data.amount)}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t(
                  "charge_details.details.collected_by_direct_debit_label",
                )}
              >
                <YesNoValue
                  value={chargeDetails.data.isCollectedByDirectDebit}
                />
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("charge_details.details.vat_applicable_label")}
              >
                <YesNoValue value={chargeDetails.data.isTaxable} />
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("charge_details.details.include_on_invoices_label")}
              >
                <YesNoValue value={chargeDetails.data.isIncludedOnInvoice} />
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("charge_details.details.status_label")}
              >
                {chargeDetails.data.isActive
                  ? t("charge_details.details.active_label")
                  : t("charge_details.details.inactive_label")}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("charge_details.details.created_label")}
              >
                {`${locale.formatDate(chargeDetails.data.createdDate)} - ${
                  chargeDetails.data.createdBy
                }`}
              </DescriptionList.Item>
            </DescriptionList>
          </Surface>
        );
      }}
    />
  );
}
