import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { Loader } from "@ldms/mui-sdk/templates";
import { Box } from "@mui/material";
import { AppError } from "common/components";
import Surface from "common/components/Surface";
import { useLocale } from "common/hooks";
import { useApi } from "common/providers";
import { AgreementQueryApi } from "generated/core/apis";
import { PaymentInstructionModel } from "generated/core/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ErrorLike } from "support/error-handler";
import useSWR from "swr";

function useErrorCodeTranslations(): Map<
  string,
  {
    message: string;
    title: string;
  }
> {
  const { t } = useTranslation("agreements");

  return new Map([
    [
      "paymentInstruction404",
      {
        message: t("error.payment_instruction_404_message"),
        title: t("error.payment_instruction_404"),
      },
    ],
  ]);
}

interface PaymentInstructionDetailsProps {
  paymentInstructionId: number;
  agreementId: number;
}

export default function PaymentInstructionDetails({
  paymentInstructionId,
  agreementId,
}: PaymentInstructionDetailsProps): ReactElement {
  const { t } = useTranslation(["agreements", "common"]);
  const errorCodeTranslations = useErrorCodeTranslations();
  const locale = useLocale();
  const { formatAmount } = useFormat();
  const api = useApi(AgreementQueryApi);
  const paymentInstruction = useSWR<PaymentInstructionModel, ErrorLike>(
    [
      "/agreement/:agreementId/payment/paymentInstructionId",
      agreementId,
      paymentInstructionId,
    ],
    (_, id: number, instructionId) =>
      api.getPaymentInstruction({
        agreementId: id,
        paymentInstructionId: instructionId,
      }),
  );

  const renderPaymentInstructionDetails = (): ReactElement => {
    if (paymentInstruction.error || !paymentInstruction.data) {
      const errorCodeTranslation = errorCodeTranslations?.get(
        `paymentInstruction${paymentInstruction.error?.status}`,
      );
      return (
        <AppError
          message={errorCodeTranslation?.message}
          title={errorCodeTranslation?.title}
        />
      );
    }

    const { details, payee, bank } = paymentInstruction.data;

    return (
      <Box
        display="grid"
        gap={2}
        gridTemplateColumns={{
          lg: "repeat(2, minmax(0, 1fr))",
          xl: "repeat(3, minmax(0, 1fr))",
        }}
      >
        <Surface title={t("payments.details.details_title")}>
          <DescriptionList label={t("payments.details.details_title")}>
            <DescriptionList.Item label={t("payments.details.method_label")}>
              {details.method}
            </DescriptionList.Item>

            <DescriptionList.Item label={t("payments.details.type_label")}>
              {details.schedule}
            </DescriptionList.Item>

            <DescriptionList.Item label={t("payments.details.amount_label")}>
              {formatAmount(details.amount)}
            </DescriptionList.Item>

            <DescriptionList.Item
              label={t("payments.details.day_of_month_label")}
            >
              {locale.formatNumberOrdinal(details.day)}
            </DescriptionList.Item>

            <DescriptionList.Item
              label={t("payments.details.start_date_label")}
            >
              {details?.startDate &&
                locale.formatDate(new Date(details.startDate))}
            </DescriptionList.Item>

            <DescriptionList.Item label={t("payments.details.end_date_label")}>
              {details.endDate && locale.formatDate(new Date(details?.endDate))}
            </DescriptionList.Item>

            <DescriptionList.Item
              label={t("payments.details.signature_date_label")}
            >
              {details.signatureDate &&
                locale.formatDate(new Date(details?.signatureDate))}
            </DescriptionList.Item>

            <DescriptionList.Item label={t("payments.details.reference_label")}>
              {details.reference}
            </DescriptionList.Item>
          </DescriptionList>
        </Surface>
        <Surface title={t("payments.payee_details.payee_details_title")}>
          <DescriptionList
            label={t("payments.payee_details.payee_details_title")}
          >
            <DescriptionList.Item
              label={t("payments.payee_details.payee_label")}
            >
              {payee?.name}
            </DescriptionList.Item>

            <DescriptionList.Item
              label={t("payments.payee_details.type_label")}
            >
              {payee?.type}
            </DescriptionList.Item>

            <DescriptionList.Item
              label={t("payments.payee_details.address_label")}
            >
              {[
                payee?.address?.addressLine1,
                payee?.address?.addressLine2,
                payee?.address?.addressLine3,
                payee?.address?.addressLine4,
                payee?.address?.postcode,
              ]
                .filter(Boolean)
                .join(" ")}
            </DescriptionList.Item>

            <DescriptionList.Item
              label={t("payments.payee_details.phone_label")}
            >
              {payee?.telephoneNumber}
            </DescriptionList.Item>

            <DescriptionList.Item
              label={t("payments.payee_details.email_label")}
            >
              {payee?.email}
            </DescriptionList.Item>
          </DescriptionList>
        </Surface>
        <Surface title={t("payments.bank_details.bank_details_title")}>
          <DescriptionList
            label={t("payments.bank_details.bank_details_title")}
          >
            <DescriptionList.Item label={t("payments.bank_details.bank_label")}>
              {bank?.name}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={t("payments.bank_details.name_on_account_label")}
            >
              {bank?.nameOnAccount}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={t("payments.bank_details.sort_code_label")}
            >
              {bank?.sortCode}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={t("payments.bank_details.account_number_label")}
            >
              {bank?.accountNumber}
            </DescriptionList.Item>
            <DescriptionList.Item label={t("payments.bank_details.iban_label")}>
              {bank?.iban}
            </DescriptionList.Item>
            <DescriptionList.Item label={t("payments.bank_details.bic_label")}>
              {bank?.bic}
            </DescriptionList.Item>
          </DescriptionList>
        </Surface>
      </Box>
    );
  };

  return (
    <Loader
      ready={Boolean(paymentInstruction.data || paymentInstruction.error)}
      render={renderPaymentInstructionDetails}
    />
  );
}
