/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (charge types)
 * API for managing Charge Types within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Amend Charge type active status
 * @export
 * @interface UpdateChargeTypeActiveStatusModel
 */
export interface UpdateChargeTypeActiveStatusModel {
    /**
     * Indicates whether the charge type is currently active
     * @type {boolean}
     * @memberof UpdateChargeTypeActiveStatusModel
     */
    isActive: boolean;
}

/**
 * Check if a given object implements the UpdateChargeTypeActiveStatusModel interface.
 */
export function instanceOfUpdateChargeTypeActiveStatusModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isActive" in value;

    return isInstance;
}

export function UpdateChargeTypeActiveStatusModelFromJSON(json: any): UpdateChargeTypeActiveStatusModel {
    return UpdateChargeTypeActiveStatusModelFromJSONTyped(json, false);
}

export function UpdateChargeTypeActiveStatusModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateChargeTypeActiveStatusModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isActive': json['isActive'],
    };
}

export function UpdateChargeTypeActiveStatusModelToJSON(value?: UpdateChargeTypeActiveStatusModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isActive': value.isActive,
    };
}

