/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (charge types)
 * API for managing Charge Types within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Amend Charge type details
 * @export
 * @interface UpdateChargeTypeDetailsModel
 */
export interface UpdateChargeTypeDetailsModel {
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof UpdateChargeTypeDetailsModel
     */
    amount: string;
    /**
     * Indicates whether a charge of this type is to be collected by Direct debit
     * @type {boolean}
     * @memberof UpdateChargeTypeDetailsModel
     */
    isCollectedByDirectDebit?: boolean;
    /**
     * Indicates whether a charge of this type is subject to Tax (E.g. VAT)
     * @type {boolean}
     * @memberof UpdateChargeTypeDetailsModel
     */
    isTaxable: boolean;
    /**
     * Indicates whether a charge of this type is to be included on invoices
     * @type {boolean}
     * @memberof UpdateChargeTypeDetailsModel
     */
    isIncludedOnInvoice?: boolean;
    /**
     * Indicates whether the charge type is currently active
     * @type {boolean}
     * @memberof UpdateChargeTypeDetailsModel
     */
    isActive: boolean;
}

/**
 * Check if a given object implements the UpdateChargeTypeDetailsModel interface.
 */
export function instanceOfUpdateChargeTypeDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "isTaxable" in value;
    isInstance = isInstance && "isActive" in value;

    return isInstance;
}

export function UpdateChargeTypeDetailsModelFromJSON(json: any): UpdateChargeTypeDetailsModel {
    return UpdateChargeTypeDetailsModelFromJSONTyped(json, false);
}

export function UpdateChargeTypeDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateChargeTypeDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'isCollectedByDirectDebit': !exists(json, 'isCollectedByDirectDebit') ? undefined : json['isCollectedByDirectDebit'],
        'isTaxable': json['isTaxable'],
        'isIncludedOnInvoice': !exists(json, 'isIncludedOnInvoice') ? undefined : json['isIncludedOnInvoice'],
        'isActive': json['isActive'],
    };
}

export function UpdateChargeTypeDetailsModelToJSON(value?: UpdateChargeTypeDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'isCollectedByDirectDebit': value.isCollectedByDirectDebit,
        'isTaxable': value.isTaxable,
        'isIncludedOnInvoice': value.isIncludedOnInvoice,
        'isActive': value.isActive,
    };
}

