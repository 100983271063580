/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentInstructionsListItemModel
 */
export interface PaymentInstructionsListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof PaymentInstructionsListItemModel
     */
    readonly id: number;
    /**
     * A read only Id
     * @type {number}
     * @memberof PaymentInstructionsListItemModel
     */
    readonly agreementId: number;
    /**
     * The name of the payee
     * @type {string}
     * @memberof PaymentInstructionsListItemModel
     */
    payee: string;
    /**
     * The payment method
     * @type {string}
     * @memberof PaymentInstructionsListItemModel
     */
    method: string;
    /**
     * The type of schedule
     * @type {string}
     * @memberof PaymentInstructionsListItemModel
     */
    schedule: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof PaymentInstructionsListItemModel
     */
    amount: string;
    /**
     * Payment day
     * @type {number}
     * @memberof PaymentInstructionsListItemModel
     */
    day: number;
    /**
     * A format for dates
     * @type {Date}
     * @memberof PaymentInstructionsListItemModel
     */
    endDate?: Date;
    /**
     * Indicates whether the payment instruction is active or not
     * @type {boolean}
     * @memberof PaymentInstructionsListItemModel
     */
    active: boolean;
    /**
     * A format for dates
     * @type {Date}
     * @memberof PaymentInstructionsListItemModel
     */
    signatureDate?: Date;
}

/**
 * Check if a given object implements the PaymentInstructionsListItemModel interface.
 */
export function instanceOfPaymentInstructionsListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "agreementId" in value;
    isInstance = isInstance && "payee" in value;
    isInstance = isInstance && "method" in value;
    isInstance = isInstance && "schedule" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "day" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function PaymentInstructionsListItemModelFromJSON(json: any): PaymentInstructionsListItemModel {
    return PaymentInstructionsListItemModelFromJSONTyped(json, false);
}

export function PaymentInstructionsListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentInstructionsListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'agreementId': json['agreementId'],
        'payee': json['payee'],
        'method': json['method'],
        'schedule': json['schedule'],
        'amount': json['amount'],
        'day': json['day'],
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'active': json['active'],
        'signatureDate': !exists(json, 'signatureDate') ? undefined : (new Date(json['signatureDate'])),
    };
}

export function PaymentInstructionsListItemModelToJSON(value?: PaymentInstructionsListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payee': value.payee,
        'method': value.method,
        'schedule': value.schedule,
        'amount': value.amount,
        'day': value.day,
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString().substring(0,10)),
        'active': value.active,
        'signatureDate': value.signatureDate === undefined ? undefined : (value.signatureDate.toISOString().substring(0,10)),
    };
}

