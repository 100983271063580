import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { AppBarIconButton, AppNavigationList } from "@ldms/mui-sdk/components";
import { List, Settings } from "@mui/icons-material";
import { Badge, Box, Stack } from "@mui/material";
import { useListTasks } from "api/tasks/listTasks";
import SearchContainer from "apps/servicing/containers/SearchContainer";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";

const Navigation: FC<{
  taskDrawer: { isOpen: boolean; toggle: () => void };
}> = ({ taskDrawer }) => {
  const { t } = useTranslation("servicing");
  const appConfig = useAppConfiguration();
  const location = useLocation();
  const tasks = useListTasks({
    params: {
      assignedToCurrentUser: true,
    },
  });

  const numberOfUserTasks = tasks.data?.paging.total;

  return (
    <Stack direction="row" flex={1}>
      <AppNavigationList
        label={t("primary_navigation")}
        pathname={location.pathname}
      >
        <AppNavigationList.ItemButton
          LinkComponent={ReactRouterLink}
          to={`${appConfig.appRoutes.servicing}/customers`}
          aria-label={t("navigation.customers_link")}
        >
          {t("navigation.customers_link")}
        </AppNavigationList.ItemButton>
        <AppNavigationList.ItemButton
          LinkComponent={ReactRouterLink}
          to={`${appConfig.appRoutes.servicing}/agreements`}
          aria-label={t("navigation.agreements_link")}
        >
          {t("navigation.agreements_link")}
        </AppNavigationList.ItemButton>
      </AppNavigationList>

      <Box display="flex" marginLeft="auto" alignItems="center">
        <AccessControl allowedPermissions={["servicing:servicing:view"]}>
          <Box marginRight={2}>
            <SearchContainer />
          </Box>
        </AccessControl>

        <AppNavigationList
          pathname={location.pathname}
          label={t("secondary_navigation")}
        >
          <li>
            <AppBarIconButton
              label={t("tasks_button")}
              selected={taskDrawer.isOpen}
              onClick={() => taskDrawer.toggle()}
            >
              <Badge color="error" badgeContent={numberOfUserTasks}>
                <List />
              </Badge>
            </AppBarIconButton>
          </li>
          <AccessControl
            allowedPermissions={[
              "servicing:companies:manage",
              "servicing:bank-accounts:elevated",
              "servicing:vat-codes:manage",
              "servicing:third-parties:manage",
            ]}
          >
            <li>
              <AppBarIconButton
                component={ReactRouterLink}
                label={t("settings_button")}
                to="/servicing/settings"
              >
                <Settings />
              </AppBarIconButton>
            </li>
          </AccessControl>
        </AppNavigationList>
      </Box>
    </Stack>
  );
};

export default Navigation;
