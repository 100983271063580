/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (charge types)
 * API for managing Charge Types within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChargeTypeHistoryListItemModel,
  ChargeTypeListItemModel,
  GetChargeTypeDetailsModel,
} from '../models/index';
import {
    ChargeTypeHistoryListItemModelFromJSON,
    ChargeTypeHistoryListItemModelToJSON,
    ChargeTypeListItemModelFromJSON,
    ChargeTypeListItemModelToJSON,
    GetChargeTypeDetailsModelFromJSON,
    GetChargeTypeDetailsModelToJSON,
} from '../models/index';

export interface GetChargeTypeDetailsRequest {
    code: string;
}

export interface ListChargeTypeHistoryRequest {
    code: string;
}

export interface ListChargeTypesRequest {
    offset?: number;
    limit?: number;
}

/**
 * 
 */
export class ChargeTypeQueryApi extends runtime.BaseAPI {

    /**
     * Get charge type details
     */
    async getChargeTypeDetailsRaw(requestParameters: GetChargeTypeDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChargeTypeDetailsModel>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getChargeTypeDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["charge-types:view"]);
        }

        const response = await this.request({
            path: `/charge-types/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChargeTypeDetailsModelFromJSON(jsonValue));
    }

    /**
     * Get charge type details
     */
    async getChargeTypeDetails(requestParameters: GetChargeTypeDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChargeTypeDetailsModel> {
        const response = await this.getChargeTypeDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List charge type history
     */
    async listChargeTypeHistoryRaw(requestParameters: ListChargeTypeHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ChargeTypeHistoryListItemModel>>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling listChargeTypeHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["charge-types:view"]);
        }

        const response = await this.request({
            path: `/charge-types/{code}/history`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChargeTypeHistoryListItemModelFromJSON));
    }

    /**
     * List charge type history
     */
    async listChargeTypeHistory(requestParameters: ListChargeTypeHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ChargeTypeHistoryListItemModel>> {
        const response = await this.listChargeTypeHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View Charge Types
     */
    async listChargeTypesRaw(requestParameters: ListChargeTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ChargeTypeListItemModel>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["charge-types:view"]);
        }

        const response = await this.request({
            path: `/charge-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChargeTypeListItemModelFromJSON));
    }

    /**
     * View Charge Types
     */
    async listChargeTypes(requestParameters: ListChargeTypesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ChargeTypeListItemModel>> {
        const response = await this.listChargeTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
