import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { Stack } from "@mui/material";
import { useGetChargeTypeDetails } from "api/charge-types/getChargeTypeDetails";
import ChargeTypeDetailsContainer from "apps/servicing/modules/settings/containers/ChargeTypeDetailsContainer";
import ListChargeTypeHistoryContainer from "apps/servicing/modules/settings/containers/ListChargeTypeHistoryContainer/ListChargeTypeHistoryContainer";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

interface ChargeDetailsDetailsViewProps {
  chargeCode: string;
}

function ChargeDetailsView({
  chargeCode,
}: Readonly<ChargeDetailsDetailsViewProps>) {
  const { t } = useTranslation("servicing");
  const appConfig = useAppConfiguration();
  const chargeDetails = useGetChargeTypeDetails(chargeCode);

  return (
    <main>
      <ViewLayout
        title={chargeDetails.data?.name}
        breadcrumbs={[
          {
            href: `${appConfig.appRoutes.servicing}/settings/charges`,
            label: t("charge_details.breadcrumb_link_label"),
          },
        ]}
      >
        <ContainedLayout>
          <Stack gap={3}>
            <ChargeTypeDetailsContainer chargeCode={chargeCode} />
            <ListChargeTypeHistoryContainer chargeCode={chargeCode} />
          </Stack>
        </ContainedLayout>
      </ViewLayout>
    </main>
  );
}

export default withProtectedView({
  allowedPermissions: ["servicing:charges:manage"],
})(ChargeDetailsView);
