/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Bank Accounts within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The Contact type
 * @export
 */
export const ContactTypeModel = {
    Director: 'Director',
    Finance: 'Finance',
    Guarantor: 'Guarantor',
    Invoicing: 'Invoicing',
    JointCustomer: 'Joint Customer',
    Partner: 'Partner',
    Proprietor: 'Proprietor',
    Representative: 'Representative'
} as const;
export type ContactTypeModel = typeof ContactTypeModel[keyof typeof ContactTypeModel];


export function ContactTypeModelFromJSON(json: any): ContactTypeModel {
    return ContactTypeModelFromJSONTyped(json, false);
}

export function ContactTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactTypeModel {
    return json as ContactTypeModel;
}

export function ContactTypeModelToJSON(value?: ContactTypeModel | null): any {
    return value as any;
}

