import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { AppBarIconButton, AppNavigationList } from "@ldms/mui-sdk/components";
import { Settings } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";

const Navigation = () => {
  const { t } = useTranslation("documents");
  const location = useLocation();

  return (
    <Box sx={{ display: "flex", marginLeft: "auto" }}>
      <AppNavigationList
        label={t("secondary_navigation")}
        pathname={location.pathname}
      >
        <li>
          <AccessControl
            allowedPermissions={[
              "documents:templates:manage",
              "documents:portfolios:manage",
            ]}
          >
            <AppBarIconButton
              label={t("settings_button")}
              component={ReactRouterLink}
              to="/documents/settings"
            >
              <Settings />
            </AppBarIconButton>
          </AccessControl>
        </li>
      </AppNavigationList>
    </Box>
  );
};

export default Navigation;
