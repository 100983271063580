import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers/ApiProvider";
import { AgreementCommandApi } from "generated/core/apis/AgreementCommandApi";

export const useCancelPaymentIntruction = (
  agreementId: number,
  paymentInstructionId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const agreementCommandApi = useApi(AgreementCommandApi);

  return useCommand<void, void, ResponseError>(
    () =>
      agreementCommandApi.cancelPaymentInstruction({
        agreementId,
        paymentInstructionId,
      }),
    options,
  );
};
