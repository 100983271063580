import { QueryKey } from "api";

export const all = (): QueryKey => ["agreements"];

export const lists = (): QueryKey => [...all(), "list"];

export const list = (params?: {
  query?: string;
  agreementNumber?: string;
  active?: boolean;
}): QueryKey => [
  ...lists(),
  params?.query,
  params?.agreementNumber,
  params?.active,
];

export const details = (): QueryKey => [...all(), "detail"];

export const detail = (id: number): QueryKey => [...details(), id];
