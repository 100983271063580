import { useQuery } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { ChargeTypeQueryApi } from "generated/core/apis";
import { keys } from ".";

export const useListChargeTypeHistory = (code: string) => {
  const api = useApi(ChargeTypeQueryApi);

  return useQuery(keys.list(code), () => api.listChargeTypeHistory({ code }));
};
