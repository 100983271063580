import { EditButton } from "@ldms/mui-sdk/components";
import { AmountField } from "@ldms/mui-sdk/forms";
import { FormDialog } from "@ldms/mui-sdk/templates";
import { Box, Typography } from "@mui/material";
import { useUpdateChargeTypeDetails } from "api/charge-types";
import { ChargeCheckbox } from "apps/servicing/modules/settings/containers/AddChargeContainer/AddChargeContainer";
import { useYupResolver } from "common/hooks";
import useModal from "common/hooks/useModal";
import { SubTypeModel } from "generated/core/models";
import { useTranslation } from "react-i18next";

interface EditChargeTypeFieldSet {
  amount: number;
  isCollectedByDirectDebit?: boolean;
  isTaxable: boolean;
  isIncludedOnInvoice?: boolean;
  isActive: boolean;
  subType: SubTypeModel;
}

interface EditChargeTypeContainerProps {
  chargeDetails: EditChargeTypeFieldSet;
  chargeCode: string;
}

const EditChargeTypeContainer = ({
  chargeDetails,
  chargeCode,
}: Readonly<EditChargeTypeContainerProps>) => {
  const { t } = useTranslation("servicing");
  const { open, close, isOpen } = useModal();

  const amountLabel = "charges.edit_charge.amount_label";

  const resolver = useYupResolver<EditChargeTypeFieldSet>((yup) =>
    yup.object().shape({
      amount: yup
        .number(t(amountLabel))
        .nullable()
        .isRequired(t(amountLabel))
        .minAmount(0, t(amountLabel))
        .maxAmount(999999.99, t(amountLabel)),
      isCollectedByDirectDebit: yup.boolean(),
      isTaxable: yup.boolean(),
      isIncludedOnInvoice: yup.boolean(),
      isActive: yup.boolean(),
    }),
  );

  const updateChargeTypeDetails = useUpdateChargeTypeDetails(chargeCode, {
    onSuccess: close,
  });

  const onEditChargeTypeSubmit = async (chargeType: EditChargeTypeFieldSet) => {
    await updateChargeTypeDetails.execute({
      ...chargeType,
      isCollectedByDirectDebit:
        chargeDetails.subType === SubTypeModel.Charge
          ? chargeType.isCollectedByDirectDebit
          : undefined,
      isIncludedOnInvoice:
        chargeDetails.subType === SubTypeModel.Charge
          ? chargeType.isIncludedOnInvoice
          : undefined,
      amount: chargeType.amount.toFixed(2),
    });
  };

  return (
    <>
      <EditButton
        aria-label={t("charges.edit_charge.button_label")}
        color="primary"
        onClick={open}
      />

      <FormDialog
        title={t("charges.edit_charge.heading_label")}
        onSubmit={onEditChargeTypeSubmit}
        onClose={close}
        open={isOpen}
        resolver={resolver}
        defaultValues={{ ...chargeDetails }}
        disabled={updateChargeTypeDetails.isExecuting}
      >
        {(form) => {
          return (
            <>
              <AmountField
                control={form.control}
                label={t("charges.edit_charge.amount_label")}
                name="amount"
                required
                error={Boolean(form.formState.errors.amount?.message)}
                helperText={form.formState.errors.amount?.message}
              />

              {chargeDetails.subType === SubTypeModel.Charge && (
                <ChargeCheckbox
                  control={form.control}
                  label="charges.edit_charge.collect_by_dd_label"
                  name="isCollectedByDirectDebit"
                />
              )}

              <ChargeCheckbox
                control={form.control}
                label="charges.edit_charge.vat_applicable_label"
                name="isTaxable"
              />

              {chargeDetails.subType === SubTypeModel.Charge && (
                <ChargeCheckbox
                  control={form.control}
                  label="charges.edit_charge.include_on_invoices_label"
                  name="isIncludedOnInvoice"
                />
              )}

              <ChargeCheckbox
                control={form.control}
                label="charges.edit_charge.active_label"
                name="isActive"
              />

              {updateChargeTypeDetails.error && (
                <Box>
                  <Typography color="error">
                    {t("common:error.default")}
                  </Typography>
                </Box>
              )}
            </>
          );
        }}
      </FormDialog>
    </>
  );
};

export default EditChargeTypeContainer;
